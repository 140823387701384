var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"msf__btn",class:{
  'msf__btn--noArrow': _vm.noArrow,
  'msf__btn--back': _vm.back,
  'msf__btn--hide': _vm.hide,
  'msf__btn--text': _vm.text,
  'msf__btn--link': _vm.link,
  'msf__btn--pending': _vm.pending,
  },attrs:{"href":"#0","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.onClick($event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }