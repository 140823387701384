<template>
  <a
    href="#0"
    class="msf__btn"
    :disabled="!isValid"
    :class="{
    'msf__btn--noArrow': noArrow,
    'msf__btn--back': back,
    'msf__btn--hide': hide,
    'msf__btn--text': text,
    'msf__btn--link': link,
    'msf__btn--pending': pending,
    }"
    @click.prevent="onClick"
  >
    <slot/>
  </a>
</template>

<script>
import { SET_STEP } from '../store/mutation-types';

export default {
  props: {
    noArrow: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    beforeNext: {
      type: Function,
    },
    beforeSync: {
      type: Function,
      default: () => {},
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onClick() {
      this.beforeSync();

      if (this.pending || !this.isValid) return;

      if (typeof this.beforeNext !== 'undefined') {
        if (!(await this.beforeNext())) return;
      }

      if (this.step) {
        this.$store.commit(SET_STEP, this.step);
      }
    },
  },
};
</script>
