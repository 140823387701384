import Vue from 'vue';
import Vuex from 'vuex';
import {
  SET_LIMIT,
} from './mutation-types';

Vue.use(Vuex);

const {
  drupalSettings: {
    sbicircle: {
      sbiCircle: {
        dist_path: distPath = '',
        api_url: apiUrl = 'crm_proxy2',
      } = {},
    } = {},
  } = {},
} = window;

// eslint-disable-next-line no-undef
__webpack_public_path__ = distPath;

export default ({
  state: {
    rating: 0,
    email: '',
    text: '',
    steps: [
      'StepCalc',
    ],
    activeStep: 'StepCalc',
    prevStep: 'StepCalc',
    error: '',
  },
  sendData() {
    const xhr = new XMLHttpRequest();
    const json = JSON.stringify(this.getDataForSending());
    xhr.open('POST', apiUrl);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    xhr.send(`action=${encodeURIComponent('sendFeedback')}&json=${encodeURIComponent(json)}`);

    xhr.addEventListener('load', () => {
      const resp = JSON.parse(xhr.response);
      if (xhr.status === 200) {
        // TODO ответ
      }
    });
  },
  getDataForSending() {
    return { rating: this.state.rating, email: this.state.email, text: this.state.text };
  },
  mutations: {
    [SET_LIMIT](state, payload) {
      state.limit = payload;
    },
  },
});
