import Vue from 'vue';
import Vuelidate from 'vuelidate';
import MaskedInput from '../node_modules/vue-text-mask';
import App from './App.vue';
import store from './store';
import Wrapper from './components/Wrapper.vue';
import Btn from './components/Btn.vue';

Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.component('Wrapper', Wrapper);
Vue.component('Btn', Btn);
Vue.component('MaskedInput', MaskedInput);


new Vue({
  store,
  render: h => h(App),
}).$mount('#app2');
