<template>
  <Wrapper mod="contactInfo" footer>
    <div id="widget-button" v-if="!feedbackWindow && !successWindow && !end" @click="showForm()">
      <!--svg class="a3Mvmgn" fill="none" height="112" viewBox="0 0 112 112" width="112" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd)">
          <circle class="b3Mvmgn" cx="56" cy="40" fill="#2B64E2" r="32"></circle>
        </g>
        <path d="M51 39C51 39 52.25 37 56 37C59.75 37 61 39 61 39" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <animate attributeName="d" dur="5s" repeatCount="indefinite" values="M51 39C51 39 52.25 37 56 37C59.75 37 61 39 61 39; M51 38C51 38 52.25 38 56 38C59.75 38 61 38 61 38; M51 38C51 38 52.25 41 56 41C59.75 41 61 38 61 38; M51 38C51 38 52.25 38 56 38C59.75 38 61 38 61 38; M51 39C51 39 52.25 37 56 37C59.75 37 61 39 61 39;"></animate>
        </path>
        <defs>
          <filter color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse" height="112" id="filter0_dd" width="112" x="0" y="0">
            <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
            <feOffset dy="16"></feOffset>
            <feGaussianBlur stdDeviation="12"></feGaussianBlur>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
            <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow"></feBlend>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
            <feOffset dy="2"></feOffset>
            <feGaussianBlur stdDeviation="4"></feGaussianBlur>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"></feColorMatrix>
            <feBlend in2="effect1_dropShadow" mode="normal" result="effect2_dropShadow"></feBlend>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" mode="normal" result="shape"></feBlend>
          </filter>
        </defs>
      </svg-->
    </div>
    <div class="success-form" v-if="successWindow">
      <div class="close" @click="endForm()"></div>
      <div class="w-col">
        <div class="finPage">
          <div class="w-row">
            <svg width="58" height="58" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.2" d="M0 52C0 23.2812 23.2812 0 52 0C80.7188 0 104 23.2812 104 52C104 80.7188 80.7188 104 52 104C23.2812 104 0 80.7188 0 52Z" fill="#17BF7F"/>
              <path opacity="0.25" d="M43.4362 55.3013C36.8073 48.421 42.6786 37.0522 52.1262 38.4745L59.2528 39.5473C62.266 40.0009 65.3216 39.0559 67.5526 36.9805L74.993 30.059C78.7456 26.5681 84.85 28.1085 86.4969 32.9621C89.4109 41.5501 86.5388 51.0406 79.3519 56.5718L65.771 67.0241C61.1919 70.5483 54.5876 69.4694 51.368 64.6713L50.3271 63.12C49.347 61.6594 48.2404 60.2878 47.02 59.021L43.4362 55.3013Z" fill="#17BF7F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M49 63.0526L74.5 38L76 39.4737L49 66L37 54.2105L38.5 52.7368L49 63.0526Z" fill="#6B6B72"/>
            </svg>
          </div>
          <h2>Спасибо,<br>что помогаете нам<br>стать лучше!!</h2>
          <p>Ваше обращение отправлено! Вы<br>получите ответ по почте.</p>
          <div class="w-button__block"> <button class="msf__btn" @click="endForm()">Закрыть</button></div>
        </div>
      </div>
    </div>
    <div class="feedback-form" v-if="feedbackWindow">
      <div class="close" @click="closeModal()"></div>
      <div class="title">
        Помогите<br>нам стать лучше
      </div>
      <div class="rating">
        <div class="rating-title">Как вы оцениваете эту страницу?</div>
        <div class="rating-buttons">
          <div class="rating-buttons-button like" v-bind:class="{ active: rating === 1 }" @click="setRating(1)"></div>
          <div class="rating-buttons-button neutral" v-bind:class="{ active: rating === 2 }" @click="setRating(2)"></div>
          <div class="rating-buttons-button unlike" v-bind:class="{ active: rating === 3 }" @click="setRating(3)"></div>
        </div>
      </div>
      <div class="text">
        <label class="text-title">
          Ваш комментарий или вопрос<br><span>(не обязательно):</span>
          <textarea v-model="feedbackText"></textarea>
        </label>
      </div>
      <div class="email">
        <label class="email-title">
          E-mail <span>(не обязательно):</span>
          <input placeholder="Введите e-mail" type="email" v-model="email" />
        </label>
      </div>
      <button class="round-one-button msf__btn" v-bind:disabled="rating === 0" @click="sendData()">Отправить</button>
    </div>
  </Wrapper>
</template>

<script>
import store from '../../store';

export default {
  mounted() {
  },
  data() {
    return {
      end: false,
      feedbackText: '',
      feedbackWindow: false,
      successWindow: false,
      email: '',
      rating: 0,
    };
  },
  methods: {
    showForm() {
      this.feedbackWindow = true;
    },
    setRating(rating) {
      this.rating = rating;
    },
    sendData() {
      store.state.rating = this.rating;
      store.state.email = this.email;
      store.state.text = this.feedbackText;
      store.sendData();
      this.feedbackWindow = false;
      this.successWindow = true;
    },
    endForm() {
      this.end = true;
      this.successWindow = false;
    },
    closeModal() {
      this.feedbackWindow = false;
    },
  },
};
</script>
