<template>
  <keep-alive>
    <component :is="activeStep" />
  </keep-alive>
</template>

<script>
import StepCalc from './components/steps/StepCalc.vue';

require('./assets/scss/styles.scss');

export default {
  name: 'app',
  data() {
    return {
      isDev: process.env.NODE_ENV === 'development',
    };
  },
  computed: {
    activeStep() {
      return this.$store.state.activeStep;
    },
  },
  components: {
    StepCalc,
  },
};
</script>
