<template>
  <div class="msf__step" :class="classes">
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    mod: {
      type: String,
      default: null,
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classes: this.mod.split(' ').map(i => `msf__step--${i}`),
      isPending: false,
    };
  },
};
</script>
